.slide-enter {
    position: absolute;
    transform: translateX(100%);
    opacity: 0;
}

.slide-enter-active {
    transform: translateX(0);
    opacity: 1;
    transition: all 800ms ease-in;
}

.slide-exit {
    position: absolute;
    transform: translateX(0);
    opacity: 1;
}

.slide-exit-active {
    transform: translateX(-100%);
    opacity: 0;
    transition: all 800ms ease-in;
}

.slider-arrows {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    pointer-events: none;
}

.slider-arrow {
    pointer-events: auto;
    background-color: rgba(255, 255, 255, 0.3);
    border: none;
    cursor: pointer;
    padding: 10px 15px;
    font-size: 24px;
    color: #ffffff;
}

.slider-arrow.left {
    margin-left: 1rem;
}

.slider-arrow.right {
    margin-right: 1rem;
}
