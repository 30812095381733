.navbar {
    max-height: 150px;
}

.navbar-item img {
    max-height: inherit;
}

body,
html {
    margin: 0;
    padding: 0;
}

.hero.is-fullheight-with-navbar {
    min-height: calc((100vh - 150px) * 0.6);
}

.slider-arrows {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    transform: translateY(-100%);
}

.slider-arrow {
    position: absolute;
    top: 100%;
    transform: translateY(-100%);
}

.slider-arrow.left {
    left: 2rem;
}

.slider-arrow.right {
    right: 2rem;
}
