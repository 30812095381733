.services-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.services-grid .service {
    width: calc(33.33% - 1rem);
    margin-bottom: 2rem;
}

.services-grid--expanded {
    display: none;
}

.hidden {
    display: none;
}

.services-grid .service:hover {
    cursor: pointer;
}

.services-grid .service .icon {
    font-size: 3rem;
    margin-bottom: 1rem;
}

.services-grid .service .title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.services-grid .service .description {
    font-size: 1.125rem;
}

.services-grid .expanded-service {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: #fff;
    padding: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.services-grid .expanded-service .icon {
    font-size: 5rem;
    margin-bottom: 2rem;
}

.services-grid .expanded-service .title {
    font-size: 3rem;
    margin-bottom: 2rem;
}

.services-grid .expanded-service .description {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    text-align: center;
}

.services-grid .expanded-service .close-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 2rem;
    color: #000;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.services-grid .expanded-service .close-button:hover {
    color: #f44336;
}

#services .section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#services .service-tile {
    width: 100%;
    max-width: 600px;
}

#services .modal-content {
    width: 100%;
    max-width: 600px;
}

.services-expanded {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 1.5rem;
    background-color: #fff;
    z-index: 10;
}

